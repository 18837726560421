<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editBaseWeight"
    :options="{
      properties: {
        name: '',
        weight: 0,
        icon: null,
      },
      postUri: '/base_weights',
      label: $t('baseWeight.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" />
    <fancy-input
      v-model="properties.weight"
      :label="$t('baseWeight.weight.label')"
      type="number"
      :min="0"
      :step="1"
      :error="errors.weight"
      unit="g"
    />

    <multiselect
      v-model="properties.icon"
      :options="async (q) => iconOptions(q, properties.icon)"
      :loading="loadingIcons"
      :filter-results="false"
      :form-label="$t('button.icon.label')"
    >
      <template #option="{ option }">
        <product-icon class="option-icon" :icon="option" /> {{ option.name }}
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label">
          <product-icon class="option-icon" :icon="value" /> {{ value.name }}
        </div>
      </template>
    </multiselect>
  </modal-form>
</template>
<script>
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import ProductIcon from '@/components/shared/ProductIcon.vue';

export default {
  name: 'EditBaseWeightModal',
  components: {
    FancyInput,
    ModalForm,
    Multiselect,
    ProductIcon,
  },
  setup() {
    const {
      loadOptions: iconOptions,
      loading: loadingIcons,
    } = useLoadOptions('/images', 'name');

    return {
      iconOptions,
      loadingIcons,
    };
  },
};
</script>

<style scoped lang="scss">
.option-icon {
  max-width: 40px;
  max-height: 90%;
}
</style>
