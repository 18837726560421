<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('baseWeight.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editBaseWeight' , entity: {
          name: '',
          weight: 0,
          icon: null,
        } })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editBaseWeight', entity: normalizeEntity($event) })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteBaseWeight', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    />

    <edit-base-weight-modal @update="loadEntities" />
    <delete-modal type="deleteBaseWeight" entity-translation-key="baseWeight" label="name" @update="loadEntities" />
  </div>
</template>
<script>
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditVatRateModal from '@/components/entities/vat_rate/EditVatRateModal.vue';
import EditBaseWeightModal from '@/components/entities/base_weight/EditBaseWeightModal.vue';

export default {
  name: 'StoreIndexPage',
  components: {
    EditBaseWeightModal,
    DeleteModal,
    EditVatRateModal,
    EntityTable,
  },
  setup() {
    const { baseWeight: fields } = useEntityFields();
    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/base_weights', { sort: 'name' });

    const normalizeEntity = (entity) => ({
      ...entity,
      icon: entity?.icon?.['@id'],
    });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      normalizeEntity,
    };
  },
};
</script>
